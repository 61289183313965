<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { nextTick, onMounted, ref, toRaw, watch, type Ref } from 'vue';

import Datepicker from '@vuepic/vue-datepicker';
import axiosApiInstance from '@/helpers/api.axios.headers';
import AlertModal from '@/components/modals/AlertModal.vue';
import Spinner from '@/components/animations/TableSpinner.vue';
import MultiselectDropdown from '@/components/MultiselectDropdown.vue';

import type { DropdownItem } from '@/types/multiselectDropdown.interface';
import type { Accordion, AccordionItem } from '@/types/accordion.interfaces';
import type { HotelProviderInterface } from '@/types/builder/hotelProvider.interface';
import type { PackageList } from '@/types/builder/packageList.interface';

const items: Ref<Array<PackageList>> = ref([]);
const loader: Ref<boolean> = ref(true);
const itemId: Ref<string> = ref('');
const childPackages: Ref<Array<PackageList>> = ref([]);

const arrivalCountry: Ref<string> = ref('');
const arrivalCity: Ref<string> = ref('');
const period: Ref<Array<any>> = ref([]);
const transportType: Ref<string> = ref('');
const hotelName: Ref<string> = ref('');
const hotelStars: Ref<number> = ref(-1);
const hotelProvider: Ref<Array<number>> = ref([]);
const activeHotelProviders: Ref<Array<HotelProviderInterface>> = ref([]);

const hotelStarsDropdownOptions: Ref<Array<DropdownItem>> = ref([
    {
        itemId: 'oneStar',
        itemLabel: 'One Star',
        checked: true,
        itemDescription: 'One Star Hotels',
    },
    {
        itemId: 'twoStar',
        itemLabel: 'Two Stars',
        checked: true,
        itemDescription: 'Two Stars Hotels',
    },
    {
        itemId: 'threeStar',
        itemLabel: 'Three Stars',
        checked: true,
        itemDescription: 'Three Stars Hotels',
    },
    {
        itemId: 'fourStar',
        itemLabel: 'Four Stars',
        checked: true,
        itemDescription: 'Four Stars Hotels',
    },
    {
        itemId: 'fiveStar',
        itemLabel: 'Five Stars',
        checked: true,
        itemDescription: 'Five Stars Hotels',
    },
]);

const hotelProvidersButtonText: Ref<string> = ref('Filter Hotel Providers');
const hotelProvidersDropdownOptions: Ref<Array<DropdownItem>> = ref([]);

axiosApiInstance
    .get('/getActiveProviders')
    .then(function (response) {
        if (!response.data.status) {
            alert(response.data.message);
        } else {
            activeHotelProviders.value = response.data.providers;

            response.data.providers.forEach((provider: HotelProviderInterface) => {
                const proiderObj = {
                    itemId: String(provider.providerId),
                    itemLabel: String(provider.providerName),
                    checked: true,
                    itemDescription: `Select ${provider.providerName}`,
                };

                hotelProvidersDropdownOptions.value.push(proiderObj);
            });
        }
    })
    .catch(function (error) {
        alert(error);
    });

onMounted(() => {
    showPackageList();
});

const showPackageList = () => {
    loader.value = true;
    childPackages.value = [];
    axiosApiInstance
        .post('/listPackages', {
            filters: {
                arrivalCountry: arrivalCountry.value,
                arrivalCity: arrivalCity.value,
                period: period.value,
                hotelName: hotelName.value,
                hotelStars: hotelStars.value,
                hotels: {
                    providers: hotelProvider.value,
                },
                transportType: transportType.value,
            },
        })
        .then(function (response) {
            loader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                items.value = response.data.packageList as Array<PackageList>;
            }
        })
        .catch(function (error) {
            alert(error);
        });
};

const deletePackageConfirm = (id: string, isGroup: boolean) => {
    if (confirm(`Delete package ${id}?`)) {
        loader.value = true;
        axiosApiInstance
            .post('/addEditDeletePackage', {
                id: id,
                isGroup: isGroup,
                action: 'delete',
            })
            .then(function (response) {
                loader.value = false;
                if (!response.data.status) {
                    alert(response.data.message);
                } else {
                    alert(response.data.message);
                    showPackageList();
                }
            })
            .catch(function (error) {
                alert(error);
            });
    }
};

const getFullPackageList = (id: string) => {
    childPackages.value = [];
    axiosApiInstance
        .post('/getFullPackageList', {
            id: id,
        })
        .then(function (response) {
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                childPackages.value = response.data.fullpackageList as Array<PackageList>;
            }
        })
        .catch(function (error) {
            alert(error);
        });
};

const changePackageStatus = (id: string, isGroup: boolean, childPackage: boolean) => {
    axiosApiInstance
        .post('/activateDeactivatePackage', {
            id: id,
            isGroup: isGroup,
            status: childPackage,
        })
        .then(function (response) {
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                if (isGroup) {
                    setTimeout(() => {
                        getFullPackageList(id);
                    }, 1000);
                }
                console.log(response.data);
            }
        })
        .catch(function (error) {
            alert(error);
        });
};

const changePackageStopByStatus = (id: string, isGroup: boolean, childPackage: boolean) => {
    axiosApiInstance
        .post('/stopBuyPackage', {
            id: id,
            isGroup: isGroup,
            stopBuy: childPackage,
        })
        .then(function (response) {
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                if (isGroup) {
                    setTimeout(() => {
                        getFullPackageList(id);
                    }, 1000);
                }
                console.log(response.data);
            }
        })
        .catch(function (error) {
            alert(error);
        });
};

const resetFields = () => {
    arrivalCountry.value = '';
    arrivalCity.value = '';
    transportType.value = '';
    period.value = [];
    hotelName.value = '';
    hotelStars.value = -1;
    hotelProvider.value = [];
    showPackageList();
};

const format = (date: Array<Date>) => {
    date = toRaw(date);

    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();

    if (date[1] != null) {
        const day1 = date[1].getDate();
        const month1 = date[1].getMonth() + 1;
        const year1 = date[1].getFullYear();

        return `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
    }
};

const handleDate = (modelData: Array<Date>) => {
    modelData = toRaw(modelData);

    if (modelData === null) {
        return false;
    }

    period.value = [
        modelData[0].toISOString().split('T')[0] as string,
        modelData[1].toISOString().split('T')[0] as string,
    ];

};

const timeSince = (date: Date) => {
    // @ts-ignore: Unreachable code error
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds';
};

watch(items, (newItems) => {
    nextTick(() => {
        const accordionItems = [];

        for (let index = 0; index < toRaw(newItems).length; index++) {
            // index
            accordionItems.push({
                id: `accordion-color-heading-${index}`,
                triggerEl: document.querySelector(`#accordion-color-heading-${index}`),
                targetEl: document.querySelector(`#accordion-color-body-${index}`),
                active: false,
            });
        }

        const options = {
            onToggle: (item: Accordion) => {
                item._items.forEach((accordionItem: AccordionItem) => {
                    console.log(accordionItem);
                    if (accordionItem.active) {
                        accordionItem.triggerEl.children[1].classList.remove('hidden');
                        accordionItem.triggerEl.children[0].classList.add('hidden');
                    } else {
                        accordionItem.triggerEl.children[0].classList.remove('hidden');
                        accordionItem.triggerEl.children[1].classList.add('hidden');
                    }
                });
            },
        };

        // @ts-ignore: Unreachable code error
        new Accordion(accordionItems, options);
    });
});
</script>
<template>
    <div class="p-10">
        <div class="border-2 border-gray-400 rounded p-5">
            <div class="flex justify-between">
                <div class="text-xl dark:text-gray-300">{{ items.length }} Packages</div>
                <div>
                    <router-link class="btn-primary mr-2" :to="{ name: 'Build New Packages' }">
                        Build New Packages
                    </router-link>
                </div>
            </div>
            <hr class="border-gray-400 my-2" />
            <div class="flex justify-between">
                <div class="w-5/6">
                    <div class="flex mb-2">
                        <div class="mr-4">
                            <label
                                for="arrivalCountry"
                                class="block text-gray-700 dark:text-gray-300"
                                >Arrival Country</label
                            >
                            <input
                                type="text"
                                name="arrivalCountry"
                                id="arrivalCountry"
                                v-model="arrivalCountry"
                                autocomplete="off"
                                placeholder=""
                                class="form-control w-64 mt-1"
                            />
                        </div>
                        <div class="mr-4">
                            <label for="arrivalCity" class="block text-gray-700 dark:text-gray-300"
                                >Arrival City</label
                            >
                            <input
                                type="text"
                                name="arrivalCity"
                                id="arrivalCity"
                                autocomplete="off"
                                v-model="arrivalCity"
                                placeholder=""
                                class="form-control w-64 mt-1"
                            />
                        </div>
                        <div class="mr-4">
                            <label
                                for="datesInterval"
                                class="block text-gray-700 dark:text-gray-300"
                                >Dates Interval</label
                            >
                            <Datepicker
                                class="mt-1 w-64"
                                v-model="period"
                                range
                                :enableTimePicker="false"
                                id="datesInterval"
                                :format="format"
                                :previewFormat="format"
                                @update:modelValue="handleDate"
                            ></Datepicker>
                        </div>
                        <div class="mr-4">
                            <label
                                for="transportType"
                                class="block text-gray-700 dark:text-gray-300"
                                >Transport Type</label
                            >
                            <select
                                id="transportType"
                                name="transportType"
                                class="form-select w-64 mt-1"
                                v-model="transportType"
                            >
                                <option value="">Select Transport</option>
                                <option value="bus_charter">Bus Charter</option>
                                <option value="charter">Charter</option>
                                <option value="airline">Airline</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="mr-4">
                            <label for="hotelName" class="block text-gray-700 dark:text-gray-300"
                                >Hotel Name</label
                            >
                            <input
                                type="text"
                                name="hotelName"
                                id="hotelName"
                                autocomplete="off"
                                v-model="hotelName"
                                placeholder=""
                                class="form-control w-64 mt-1"
                            />
                        </div>
                        <div class="w-60 mr-4">
                            <label for="hotelStars" class="block text-gray-700 dark:text-gray-300"
                                >Hotel Stars</label
                            >
                            <MultiselectDropdown
                                class="mt-1 w-64"
                                :buttonText="'Hotel Rating'"
                                :dropdownId="'hotelStarsMultiselect'"
                                :dropdownOptions="hotelStarsDropdownOptions"
                            />
                        </div>
                        <div class="w-64 mr-4 -ml-1">
                            <label
                                for="hotelProvider"
                                class="block text-gray-700 dark:text-gray-300"
                                >Hotel Provider</label
                            >
                            <MultiselectDropdown
                                class="mt-1"
                                :buttonText="hotelProvidersButtonText"
                                :dropdownId="'hotelProvidersMultiselect'"
                                :dropdownOptions="toRaw(hotelProvidersDropdownOptions)"
                            />
                        </div>
                        <!-- <div class="mr-4">
                            <label
                                for="transportProvider"
                                class="block text-gray-700 dark:text-gray-300"
                                >Transport Provider</label
                            >
                            <MultiselectDropdown
                                class="mt-1"
                                :buttonText="'Transport Providers'"
                                :dropdownId="'transportProvidersMultiselect'"
                                :dropdownOptions="toRaw(hotelProvidersDropdownOptions)"
                            />
                        </div> -->
                    </div>
                </div>
                <div class="flex justify-end items-end w-1/6">
                    <div class="-mb-3">
                        <button class="btn-success mr-2" @click="showPackageList">Filter</button>
                        <button class="btn-warning mr-2" @click="resetFields">Reset</button>
                    </div>
                </div>
            </div>
        </div>
        <br />

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-0">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead
                    class="text-xs text-gray-700 uppercase bg-blue-200 dark:bg-gray-600 dark:text-gray-400"
                >
                    <tr>
                        <th scope="col" class="px-3 py-2"></th>
                        <th scope="col" class="px-3 py-2">Nr.</th>
                        <th scope="col" class="px-3 py-2">Name</th>
                        <th scope="col" class="px-3 py-2">Country</th>
                        <th scope="col" class="px-3 py-2">City</th>
                        <th scope="col" class="px-3 py-2">Provider</th>
                        <th scope="col" class="px-3 py-2">Start Date</th>
                        <th scope="col" class="px-3 py-2">End Date</th>
                        <th scope="col" class="px-3 py-2">Created/Edited by</th>
                        <th scope="col" class="px-3 py-2">Created/Edited at</th>
                        <th scope="col" class="px-3 py-2">Status</th>
                        <th scope="col" class="px-3 py-2">Stop Buy</th>
                        <th scope="col" class="px-3 py-2">Action</th>
                    </tr>
                    <tr v-show="loader">
                        <td
                            colspan="9"
                            class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 p-2"
                        >
                            <div class="flex items-center justify-center">
                                <Spinner />
                                <div class="text-lg pl-2">Loading...</div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-if="items.length === 0 && !loader"
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                        <td colspan="99" class="text-center">
                            <div class="font-bold dark:text-white text-xl uppercase my-2">
                                No packages found.
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in items" :key="index">
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td class="py-4 px-0 text-center">
                            <button
                                :id="'accordion-color-heading-' + index"
                                @click="getFullPackageList(item.id)"
                                title="Expand Package Group"
                            >
                                <svg
                                    class="h-5 w-5 fill-blue-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M464 96h-192l-64-64h-160C21.5 32 0 53.5 0 80v352C0 458.5 21.5 480 48 480h416c26.5 0 48-21.5 48-48v-288C512 117.5 490.5 96 464 96zM336 311.1h-56v56C279.1 381.3 269.3 392 256 392c-13.27 0-23.1-10.74-23.1-23.1V311.1H175.1C162.7 311.1 152 301.3 152 288c0-13.26 10.74-23.1 23.1-23.1h56V207.1C232 194.7 242.7 184 256 184s23.1 10.74 23.1 23.1V264h56C349.3 264 360 274.7 360 288S349.3 311.1 336 311.1z"
                                    />
                                </svg>
                                <svg
                                    class="hidden h-5 w-5 fill-yellow-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M448 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64zM184 272c-13.3 0-24 10.7-24 24s10.7 24 24 24H328c13.3 0 24-10.7 24-24s-10.7-24-24-24H184z"
                                    />
                                </svg>
                            </button>
                        </td>
                        <td class="p-2 text-center">
                            {{ index + 1 }}
                        </td>
                        <th scope="row" class="p-2">
                            <div
                                class="relative p-3 font-medium text-gray-900 dark:text-white whitespace-nowrap block truncate w-64"
                                :title="String(item.name)"
                            >
                                <span
                                    v-show="
                                        new Date(item.dateAdded).setHours(0, 0, 0, 0) ==
                                        new Date().setHours(0, 0, 0, 0)
                                    "
                                    class="absolute z-0 top-0 left-0 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0 rounded dark:bg-green-200 dark:text-green-900"
                                >
                                    New -
                                    {{ timeSince(new Date(item.dateAdded)) }}
                                </span>
                                <span
                                    v-show="
                                        new Date(
                                            item.dateUpdated != null ? item.dateUpdated : ''
                                        ).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
                                    "
                                    class="absolute z-0 top-0 left-0 bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0 rounded dark:bg-yellow-200 dark:text-yellow-900"
                                >
                                    Updated -
                                    {{
                                        timeSince(
                                            new Date(
                                                item.dateUpdated != null ? item.dateUpdated : ''
                                            )
                                        )
                                    }}
                                </span>
                                {{ item.name }}
                            </div>
                        </th>
                        <td class="p-2">
                            {{ item.country }}
                        </td>
                        <td class="p-2">
                            {{ item.city }}
                        </td>
                        <td class="p-2">
                            <div
                                class="relative p-2 font-medium text-gray-500 whitespace-nowrap block truncate w-32 dark:text-gray-400"
                                :title="String(item.providers)"
                            >
                                {{ item.providers.join() }}
                            </div>
                        </td>
                        <td class="p-2">
                            {{ item.startDate }}
                        </td>
                        <td class="p-2">
                            {{ item.endDate }}
                        </td>
                        <td class="p-2">
                            {{ item.addUserName != null ? item.addUserName : item.editUserName }}
                        </td>
                        <td class="p-2">
                            {{ item.dateAdded != null ? item.dateAdded : '' }}
                            {{ item.dateUpdated != null ? '/' : '' }}
                            <br />{{ item.dateUpdated != null ? item.dateUpdated : '' }}
                        </td>
                        <td class="p-2">
                            <label class="inline-flex relative items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                    :checked="item.packageStatus === true"
                                    @change="
                                        changePackageStatus(
                                            item.id,
                                            item.isGroup,
                                            ($event.target as HTMLInputElement).checked
                                        )
                                    "
                                />
                                <div
                                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                                ></div>
                            </label>
                        </td>
                        <td class="p-2">
                            <label class="inline-flex relative items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                    :checked="item.packageStopBuy === true"
                                    @change="
                                        changePackageStopByStatus(
                                            item.id,
                                            item.isGroup,
                                            ($event.target as HTMLInputElement).checked
                                        )
                                    "
                                />
                                <div
                                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                                ></div>
                            </label>
                        </td>
                        <td class="p-2">
                            <router-link
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                :to="{
                                    name: 'Build New Packages',
                                    params: {
                                        id: item.id,
                                        isGroup: String(item.isGroup),
                                    },
                                }"
                            >
                                Edit
                            </router-link>
                            |
                            <a
                                type="button"
                                @click="deletePackageConfirm(String(item.id), item.isGroup)"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                >Delete</a
                            >
                        </td>
                    </tr>
                    <tr
                        :id="'accordion-color-body-' + index"
                        class="hidden"
                        :aria-labelledby="'accordion-color-body-' + index"
                    >
                        <td colspan="99">
                            <div class="overflow-x-auto relative">
                                <table
                                    class="w-full text-xs text-left text-gray-500 dark:text-gray-400"
                                >
                                    <thead
                                        class="text-gray-700 uppercase dark:text-white dark:bg-gray-600"
                                    >
                                        <th scope="col" class="px-3 py-2"></th>
                                        <th scope="col" class="px-3 py-2">Nr.</th>
                                        <th scope="col" class="px-3 py-2">Name</th>
                                        <th scope="col" class="px-3 py-2">Country</th>
                                        <th scope="col" class="px-3 py-2">City</th>
                                        <th scope="col" class="px-3 py-2">Hotel</th>
                                        <th scope="col" class="px-3 py-2">Provider</th>
                                        <th scope="col" class="px-3 py-2">Start Date</th>
                                        <th scope="col" class="px-3 py-2">End Date</th>
                                        <th scope="col" class="px-3 py-2">Transport</th>
                                        <th scope="col" class="px-3 py-2">Status</th>
                                        <th scope="col" class="px-3 py-2">Stop Buy</th>
                                        <th scope="col" class="px-3 py-2">Action</th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(childPackage, index2) in childPackages"
                                            :key="index2"
                                            class="bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700"
                                        >
                                            <td class="py-4 px-2">
                                                <svg
                                                    class="h-4 w-4 fill-blue-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 384 512"
                                                >
                                                    <path
                                                        d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256z"
                                                    />
                                                </svg>
                                            </td>
                                            <td class="py-2 px-4 text-center">
                                                {{ index2 + 1 }}
                                            </td>
                                            <th
                                                scope="row"
                                                class="py-2 px-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                <span title="Hotel recommended" v-if="childPackage.isRecommended" class="text-blue-600 text-lg">&#9733;</span>
                                                {{ childPackage.name }}
                                            </th>
                                            <td class="py-2 px-4">
                                                {{ childPackage.country }}
                                            </td>
                                            <td class="py-2 px-4">
                                                {{ childPackage.city }}
                                            </td>
                                            <td class="py-2 px-4">
                                                {{ childPackage.hotel }}
                                            </td>
                                            <td class="py-2 px-4">
                                                {{ childPackage.providers.join() }}
                                            </td>
                                            <td class="py-2 px-4">
                                                {{ childPackage.startDate }}
                                            </td>
                                            <td class="py-2 px-4">
                                                {{ childPackage.endDate }}
                                            </td>
                                            <td class="py-2px-6">
                                                {{ childPackage.transport }}
                                            </td>
                                            <td class="p-2">
                                                <label
                                                    class="inline-flex relative items-center cursor-pointer"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        class="sr-only peer"
                                                        :checked="
                                                            childPackage.packageStatus === true
                                                        "
                                                        @change="
                                                            changePackageStatus(
                                                                String(childPackage.id),
                                                                false,
                                                                ($event.target as HTMLInputElement).checked
                                                            )
                                                        "
                                                    />
                                                    <div
                                                        class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                                                    ></div>
                                                </label>
                                            </td>
                                            <td class="p-2">
                                                <label
                                                    class="inline-flex relative items-center cursor-pointer"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        class="sr-only peer"
                                                        :checked="
                                                            childPackage.packageStopBuy === true
                                                        "
                                                        @change="
                                                            changePackageStopByStatus(
                                                                String(childPackage.id),
                                                                false,
                                                                ($event.target as HTMLInputElement).checked
                                                            )
                                                        "
                                                    />
                                                    <div
                                                        class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                                                    ></div>
                                                </label>
                                            </td>
                                            <td class="py-2 px-4">
                                                <router-link
                                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                                    :to="{
                                                        name: 'Build New Packages',
                                                        params: {
                                                            id: childPackage.id,
                                                            isGroup: String(false),
                                                        },
                                                    }"
                                                >
                                                    Edit
                                                </router-link>
                                                |
                                                <a
                                                    type="button"
                                                    @click="
                                                        deletePackageConfirm(
                                                            String(childPackage.id),
                                                            false
                                                        )
                                                    "
                                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                                    >Delete</a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <AlertModal :id="itemId" />
    </div>
</template>
